

    import { defineComponent, ref, watchEffect, getCurrentInstance, nextTick, onMounted, onUnmounted, computed } from 'vue'
    import BodyContent from '../components/BodyContent.vue'
    import Column from '../components/Grid/Column.vue'
    import Grid from '../components/Grid/Grid.vue'
    import Container from '../components/Container.vue'
    import CustomCursor from '../components/CustomCursor.vue'
    import Heading from '../components/Heading.vue'
    import ContactHeader from '../components/ContactHeader.vue'
    import Paragraph from '../components/Paragraph.vue'
    import Picture from '../components/Picture.vue'
    import Projects from '../components/Projects.vue'
    import MetaInfo from '../components/MetaInfo.vue'
    import ThemeChanger from '../components/ThemeChanger.vue'
    import { useCms } from '../compositions/UseCms'
    import { useTheme } from '../compositions/UseTheme'
    import { scrollTo } from '../compositions/HeaderComposition'
    import { useTranslation } from '../compositions/UseTranslation'
    import { Theme } from '../enums/Theme'
    import { useRoute } from 'vue-router'
    import { CaseStudyInterface } from '../Interfaces/CaseStudyInterface'
    import { themeColor } from '../themeColor'

    export default defineComponent({
        name: 'CaseStudy',
        components: {
            Container,
            BodyContent,
            Column,
            CustomCursor,
            Grid,
            Heading,
            Paragraph,
            Picture,
            Projects,
            ContactHeader,
            ThemeChanger,
            MetaInfo
        },
        setup() {

            const { setTheme, setBackground } = useTheme()
            const refreshAOSHard = getCurrentInstance()?.appContext.config.globalProperties.$aos?.refreshHard
            const { isCNLocale } = useCms()
            const route = useRoute()
            const caseStudy = ref<CaseStudyInterface | null>(null)
            const caseStudyElement = ref<HTMLDivElement | null>(null)
            const { cursor } = useTranslation()
            const coverHeight = ref<string>('1000px')

            useTheme().initializeCurrentThemeBasedOnRouteMeta()

            watchEffect(() => {

                const path = isCNLocale.value ? `/cms/cn/cases/${ route.params.slug }.json` : `/cms/en/cases/${ route.params.slug }.json`

                fetch(path)
                    .then(response => response.json())
                    .then(response => {

                        caseStudy.value = response

                        console.log('setting', caseStudy?.value?.theme?.value)
                        setTheme(caseStudy?.value?.theme?.value ?? Theme.Dark)
                        setBackground(caseStudy?.value?.default_background_color ?? themeColor[caseStudy?.value?.theme?.value ?? Theme.Dark])

                        calcCoverHeight()

                    })
                    .then(() => nextTick())
                    .then(() => {

                        refreshAOSHard?.()

                    })

            })

            const scrollToContent = async() => {

                if (caseStudyElement.value) {

                    const boundingBox = caseStudyElement.value.getBoundingClientRect()

                    await scrollTo(
                        document.documentElement,
                        window.scrollY,
                        window.scrollY + boundingBox.top + window.innerHeight,
                        500
                    )

                }

            }

            function calcCoverHeight() {

                const originalWidth = caseStudy?.value?.cover?.desktop_asset?.width
                const originalHeight = caseStudy?.value?.cover?.desktop_asset?.height
                const ratio = window.innerWidth / originalWidth

                if (originalHeight && originalWidth && ratio) {

                    coverHeight.value = `${ Math.floor(originalHeight * ratio) }px`

                } else {

                    coverHeight.value = '100vh'

                }

            }

            onMounted(() => {

                window.addEventListener('resize', calcCoverHeight)

            })

            onUnmounted(() => {

                window.removeEventListener('resize', calcCoverHeight)

            })

            return {
                Theme,
                caseStudy,
                caseStudyElement,
                scrollToContent,
                cursor,
                coverHeight,
                isLightTheme: computed(() => {

                    console.log('isLight: ', caseStudy?.value?.theme?.value === 'light')
                    return caseStudy?.value?.theme?.value === 'light' as Theme

                })
            }

        }
    })
