import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative z-20 md:h-full md:flex md:items-center" }
const _hoisted_2 = {
  class: "px-10 w-full flex-col justify-center",
  style: {"width":"450px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Heading = _resolveComponent("Heading")!
  const _component_AOS = _resolveComponent("AOS")!
  const _component_Paragraph = _resolveComponent("Paragraph")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Grid = _resolveComponent("Grid")!
  const _component_Picture = _resolveComponent("Picture")!
  const _component_Container = _resolveComponent("Container")!
  const _component_CustomCursor = _resolveComponent("CustomCursor")!
  const _component_ThemeChanger = _resolveComponent("ThemeChanger")!
  const _component_BodyContent = _resolveComponent("BodyContent")!
  const _component_ContactHeader = _resolveComponent("ContactHeader")!
  const _component_Projects = _resolveComponent("Projects")!
  const _component_MetaInfo = _resolveComponent("MetaInfo")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ThemeChanger, {
      to: _ctx.isLightTheme ? _ctx.Theme.Default : _ctx.Theme.Dark,
      background: _ctx.caseStudy?.default_background_color
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Grid, { class: "mt-32 lg:hidden" }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, { class: "sm:items-center sm:text-center" }, {
              default: _withCtx(() => [
                _createVNode(_component_AOS, { type: "fade-up" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Heading, {
                      tag: "h1",
                      innerHTML: _ctx.caseStudy?.heading
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                }),
                (_ctx.caseStudy?.client_name)
                  ? (_openBlock(), _createBlock(_component_AOS, {
                      key: 0,
                      type: "fade-up"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          class: "mt-10 uppercase",
                          innerHTML: _ctx.caseStudy?.client_name
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                _createVNode(_component_AOS, { type: "fade-up" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Paragraph, {
                      class: "mt-10",
                      innerHTML: _ctx.caseStudy?.description
                    }, null, 8, ["innerHTML"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_Picture, {
          image: _ctx.caseStudy?.cover,
          class: "mt-24 lg:hidden",
          center: "",
          auto: "",
          width: "100vw",
          maxWidth: "100vw",
          height: "auto",
          lazy: false
        }, null, 8, ["image"]),
        _createVNode(_component_CustomCursor, {
          text: _ctx.cursor.scroll
        }, {
          default: _withCtx(({ eventListeners }) => [
            _createElementVNode("div", _mergeProps({ class: "case-study__cover-shell" }, eventListeners, {
              ref: "caseStudyElement",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToContent && _ctx.scrollToContent(...args))),
              style: `height: ${_ctx.coverHeight}`
            }), [
              _createElementVNode("div", {
                class: "case-study__cover z-10",
                style: _normalizeStyle(`height: ${_ctx.coverHeight}; max-height: 100vh`)
              }, [
                _createElementVNode("div", {
                  class: "absolute top-0 z-0",
                  style: _normalizeStyle({
                                    height: _ctx.coverHeight,
                                    maxHeight: '100vh',
                                    width: '100vw',
                                    maxWidth: '100vw',
                                    background: _ctx.caseStudy?.cover?.gradient_background ?? 'linear-gradient(140deg, #121212, #232323)'
                                })
                }, null, 4),
                (_ctx.caseStudy?.cover)
                  ? (_openBlock(), _createBlock(_component_Picture, {
                      key: 0,
                      width: "100vw",
                      maxWidth: "100vw",
                      maxHeight: "100vh",
                      cover: "",
                      "cover-fixed": "",
                      lazy: false,
                      image: _ctx.caseStudy?.cover
                    }, null, 8, ["image"]))
                  : _createCommentVNode("", true)
              ], 4),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Container, {
                  fluid: "",
                  class: "items-center w-full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Grid, {
                      fluid: "",
                      class: "w-full"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Column, {
                          span: 3,
                          class: "flex items-center"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode(_component_AOS, {
                                type: "fade-up",
                                "is-group": ""
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Heading, {
                                    tag: "h1",
                                    "no-margin": "",
                                    innerHTML: _ctx.caseStudy?.heading
                                  }, null, 8, ["innerHTML"])
                                ]),
                                _: 1
                              }),
                              (_ctx.caseStudy?.client_name)
                                ? (_openBlock(), _createBlock(_component_AOS, {
                                    key: 0,
                                    type: "fade-up",
                                    "is-group": "",
                                    delay: "250"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_Paragraph, {
                                        class: "mt-10 uppercase",
                                        innerHTML: _ctx.caseStudy?.client_name
                                      }, null, 8, ["innerHTML"])
                                    ]),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true),
                              _createVNode(_component_AOS, {
                                type: "fade-up",
                                "is-group": "",
                                delay: "500"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Paragraph, {
                                    class: "mt-10",
                                    innerHTML: _ctx.caseStudy?.description
                                  }, null, 8, ["innerHTML"])
                                ]),
                                _: 1
                              })
                            ])
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Column, { span: 2 }),
                        _createVNode(_component_Column, { span: 2 })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ])
            ], 16)
          ]),
          _: 1
        }, 8, ["text"])
      ]),
      _: 1
    }, 8, ["to", "background"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.caseStudy?.body, (item, idx) => {
      return (_openBlock(), _createBlock(_component_BodyContent, {
        data: item,
        key: idx,
        "uses-custom-themes": "",
        "single-space": idx > 0 ? _ctx.caseStudy?.body[idx - 1].type === 'heading_and_content' && item.type === 'heading_and_content' : false,
        "is-last": idx == _ctx.caseStudy?.body.length - 1,
        "default-background": _ctx.caseStudy?.default_background_color,
        "default-gradient": _ctx.caseStudy?.default_gradient_background,
        "fallback-theme": _ctx.isLightTheme ? _ctx.Theme.Default : _ctx.Theme.Dark
      }, null, 8, ["data", "single-space", "is-last", "default-background", "default-gradient", "fallback-theme"]))
    }), 128)),
    _createVNode(_component_ThemeChanger, {
      to: _ctx.Theme.Dark
    }, {
      default: _withCtx(() => [
        (_ctx.caseStudy?.contact_heading)
          ? (_openBlock(), _createBlock(_component_ContactHeader, {
              key: 0,
              class: "pt-16 md:pt-24",
              heading: _ctx.caseStudy?.contact_heading,
              label: _ctx.caseStudy?.contact_label,
              route: _ctx.caseStudy?.contact_entry?.slug
            }, null, 8, ["heading", "label", "route"]))
          : _createCommentVNode("", true),
        _createVNode(_component_AOS, {
          type: "fade",
          easing: "ease",
          duration: 800,
          delay: 500
        }, {
          default: _withCtx(() => [
            (_ctx.caseStudy?.related_cases_heading)
              ? (_openBlock(), _createBlock(_component_Grid, {
                  key: 0,
                  class: "mt-24 md:mt-48"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Column, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_Paragraph, {
                          innerHTML: _ctx.caseStudy?.related_cases_heading,
                          heading: ""
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        (_ctx.caseStudy?.related_cases)
          ? (_openBlock(), _createBlock(_component_Projects, {
              key: _ctx.caseStudy?.heading,
              class: "sm:mt-12 md:mt-16",
              data: _ctx.caseStudy?.related_cases
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["to"]),
    (_ctx.caseStudy?.seo)
      ? (_openBlock(), _createBlock(_component_MetaInfo, {
          key: 0,
          seo: _ctx.caseStudy.seo,
          "structured-data": _ctx.caseStudy.seo_structured_data
        }, null, 8, ["seo", "structured-data"]))
      : _createCommentVNode("", true)
  ], 64))
}