

    import { defineComponent, onBeforeMount, PropType, onBeforeUnmount } from 'vue'
    import { SeoInterface } from '../Interfaces/SeoInterface'

    interface StructuredDataInterface {
        value?: string,
        code?: string,
        mode?: string,
    }

    export default defineComponent({
        name: 'MetaInfo',
        props: {
            seo: { type: Object as PropType<SeoInterface>, required: true },
            structuredData: { type: Object as PropType<StructuredDataInterface> }
        },
        setup(props) {

            let metaElements = []

            const seoValues: Record<string, Record<string, string>> = {
                twitter: {
                    card: props.seo.twitter_card,
                    site: `@${ props.seo.twitter_handle }`,
                    title: props.seo.og_title,
                    description: props.seo.description,
                    creator: `@${ props.seo.twitter_handle }`
                },
                og: {
                    title: props.seo.og_title,
                    type: 'article',
                    url: window.location.href,
                    image: props?.seo?.image?.url,
                    'image:alt': props?.seo?.image?.alt,
                    description: props.seo.description,
                    site_name: props.seo.site_name
                }
            }

            const createMetaElement = (name: string, value: string, isItemProp = false) => {

                if (value) {

                    const metaItem = document.createElement('meta')
                    metaItem.setAttribute(isItemProp ? 'itemprop' : 'name', name)
                    metaItem.content = value
                    return metaItem

                }

            }

            onBeforeMount(() => {

                document.title = props?.seo?.compiled_title

                metaElements = [
                    createMetaElement('description', props?.seo?.description ?? props?.seo?.compiled_title),
                    createMetaElement('description', props?.seo?.description ?? props?.seo?.compiled_title, true),
                    createMetaElement('name', props.seo.title, true),
                    createMetaElement('image', props?.seo?.image?.url ?? '', true)
                ]

                for (const site in seoValues) {

                    for (const item in seoValues[site]) {

                        metaElements.push(createMetaElement(`${ site }:${ item }`, seoValues[site][item]))

                    }

                }

                for (const element of metaElements) {

                    if (element) {

                        document.head.appendChild(element)

                    }

                }

                if (props.structuredData && props.structuredData.code !== null) {

                    const structuredDataElement = new DOMParser().parseFromString(props?.structuredData?.code ?? '', 'text/xml')
                    document.head.appendChild(structuredDataElement.firstChild!)

                }

            })

            onBeforeUnmount(() => {

                for (const element of metaElements) {

                    if (element) {

                        document.head.removeChild(element)

                    }

                }

            })

            return () => []

        }
    })

